import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Error404 from '@/views/Error404.vue'
import MembershipCard from '@/views/MembershipCard.vue'
import MyAccount from '@/views/MyAccount.vue'
// import Settings from '@/views/Settings.vue'
import BenefitContainer from '@/views/BenefitContainer.vue'
import Benefit from '@/views/Benefit.vue'
import ClubContainer from '@/views/ClubContainer.vue'
import Club from '@/views/Club.vue'
// import EventContainer from '@/views/EventContainer.vue'
// import Event from '@/views/Event.vue'
import NewsContainer from '@/views/NewsContainer.vue'
import News from '@/views/News.vue'
import PageCategoryContainer from '@/views/PageCategoryContainer.vue'
import PageCategory from '@/views/PageCategory.vue'
import Page from '@/views/Page.vue'
import InvoiceContainer from '@/views/InvoiceContainer.vue'
import Invoice from '@/views/Invoice.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'root',
		redirect: {
			name: 'membershipCard',
		},
		meta: {
			hidden: true,
		},
	},
	{
		path: '/membershipcard',
		name: 'membershipCard',
		component: MembershipCard,
		meta: {
			title: 'Kortti',
			icon: 'mdi-badge-account-horizontal',
		},
		children: [
			{
				path: 'myaccount',
				name: 'myAccount',
				component: MyAccount,
				meta: {
					title: 'Omat tiedot',
					headline: 'Omat tiedot',
					icon: 'mdi-account',
					hidden: true,
				},
			},
		],
	},
	// {
	// 	path: "/settings",
	// 	name: "settings",
	// 	component: Settings,
	// 	meta: {
	// 		title: "Asetukset",
	// 		headline: "Asetukset",
	// 		icon: "mdi-settings",
	// 		hidden: true
	// 	}
	// },
	{
		path: '/clubs',
		name: 'clubContainer',
		component: ClubContainer,
		meta: {
			title: 'Seurat',
			headline: 'Seurat',
			icon: 'mdi-horseshoe',
		},
		children: [
			{
				path: ':pagename',
				name: 'club',
				component: Club,
				props: true,
				meta: {
					headline: 'Seura',
					title: 'Seura',
				},
			},
		],
	},
	{
		path: '/benefits',
		name: 'benefitContainer',
		component: BenefitContainer,
		meta: {
			title: 'Edut',
			headline: 'Jäsenedut',
			icon: 'mdi-star',
		},
		children: [
			{
				path: ':pagename',
				name: 'benefit',
				component: Benefit,
				props: true,
				meta: {
					title: 'Jäsenetu',
					headline: 'Jäsenetu',
				},
			},
		],
	},
	{
		path: '/invoices',
		name: 'invoiceContainer',
		component: InvoiceContainer,
		meta: {
			hidden: true,
			title: 'Laskut',
			headline: 'Laskut',
			icon: 'mdi-receipt',
		},
		children: [
			{
				path: ':pagename',
				name: 'invoice',
				component: Invoice,
				props: true,
				meta: {
					title: 'Lasku',
				},
			},
		],
	},
	// {
	// 	path: "/events",
	// 	name: "eventContainer",
	// 	component: EventContainer,
	// 	meta: {
	// 		title: "Tapahtumat",
	// 		icon: "mdi-calendar"
	// 	},
	// 	children: [
	// 		{
	// 			path: ":pagename",
	// 			name: "event",
	// 			component: Event,
	// 			props: true,
	// 			meta: {
	// 				title: "Tapahtuma"
	// 			}
	// 		}
	// 	]
	// },
	{
		path: '/news',
		name: 'newsContainer',
		component: NewsContainer,
		meta: {
			title: 'Uutiset',
			headline: 'Uutiset',
			icon: 'mdi-bullhorn',
		},
		children: [
			{
				path: ':pagename',
				name: 'news',
				component: News,
				props: true,
				meta: {
					title: 'Uutinen',
				},
			},
		],
	},
	{
		path: '/pages',
		name: 'pageCategoryContainer',
		component: PageCategoryContainer,
		meta: {
			title: 'Materiaalit',
			headline: 'Materiaalit',
			icon: 'mdi-file-document-multiple',
		},
		children: [
			{
				path: ':categoryname',
				name: 'pageCategory',
				component: PageCategory,
				props: true,
				meta: {
					title: 'Kategoria',
				},
				children: [
					{
						path: ':pagename',
						name: 'page',
						component: Page,
						props: true,
						meta: {
							title: 'Sivu',
						},
					},
				],
			},
		],
	},
	{
		name: 'Error404',
		path: '/error404',
		component: Error404,
		meta: {
			title: 'Sivua ei löytynyt',
			hidden: true,
		},
	},
	{
		path: '*',
		redirect: {
			name: 'Error404',
		},
		meta: {
			hidden: true,
		},
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

// Navigation guards
router.beforeEach((to, from, next) => {
	// If current route is not the first one the user visits,
	// we switch back button to the history mode.
	if (from.name !== null) {
		store.commit('setBackButtonHistoryMode', true)
	}

	// If navigation confirmation is requested, show dialog
	if (store.state.confirmNavigation) {
		store.commit('setConfirmNavigationDialog', true)
		store.commit('setConfirmNavigationRoute', to)
	} else {
		next()
	}
})

router.afterEach((to) => {
	// Change meta title
	if (to.meta && to.meta.title) {
		document.title = to.meta.title
	} else {
		document.title = to.name
	}
})

export default router
