<template>
	<div>
		<!-- Output child page -->
		<router-view />

		<!-- Output navigation if we're not on child page -->
		<template v-if="$route.name == 'pageCategory'">
			<v-container class="container--narrow">
				<FilterableList
					v-if="items.length"
					:items="items"
					@itemClick="itemClick"
				/>
				<v-alert
					v-else
					type="info"
					class="ma-3"
				>
					Ei sivuja
				</v-alert>
			</v-container>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'PageCategory',
	data: () => ({
		items: [],
	}),
	computed: {
		...mapState({
			categories: state => state.pages,
		}),
	},
	methods: {
		itemClick (item) {
			this.$router.push({ name: 'page', params: { pagename: item.name } })
		},
	},
	mounted () {
		this.$api.Pages.doRequest()

		const category = this.categories.find(item => {
			return item.name == this.$route.params.categoryname
		})

		if (!category) {
			this.$router.replace({ name: 'Error404' })
		}

		this.items = category.children
	},
}
</script>
