<template>
	<v-bottom-navigation
		app
		fixed
		color="secondary"
	>
		<v-btn
			v-for="route in items"
			:key="route.name"
			:value="route.name"
			:to="route"
		>
			<template v-if="route.meta">
				<span v-if="route.meta.title">
					{{ route.meta.title }}
				</span>
				<span v-else>
					{{ route.name }}
				</span>
				<v-icon v-if="route.meta.icon">
					{{ route.meta.icon }}
				</v-icon>
			</template>
		</v-btn>
	</v-bottom-navigation>
</template>

<script>

export default {
	name: 'BottomBar',
	props: {
		items: {
			type: Array,
			default: () => {
				return []
			},
		},
	},
}
</script>
