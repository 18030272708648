<template>
	<div>
		<!-- Output child page -->
		<router-view />

		<!-- Output navigation if we're not on child page -->
		<template v-if="$route.name == 'membershipCard'">
			<!-- Membership card -->
			<v-container class="container--narrow">
				<v-alert
					type="info"
					prominent
					class="d-none d-md-block"
				>
					Mikäli käytät jäsenhuonetta muulla kuin henkilökohtaisella laitteellasi, muistathan kirjautua ulos.
				</v-alert>
				<v-card class="membershipCard">
					<v-img
						v-if="user.hero_image"
						class="white--text align-end"
						aspect-ratio="1.628"
						:src="user.hero_image.url"
						gradient="rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, .6) 100%"
						dark
					>
						<v-card-title
							:style="{ color: user.hero_image.select_color_theme}"
							class="headline"
						>
							{{ user.firstname }} {{ user.lastname }}
						</v-card-title>
						<v-card-subtitle
							:style="{ color: user.hero_image.select_color_theme}"
							v-if="paidActiveMemberships && paidActiveMemberships.length"
						>
							<ul class="no-list">
								<li
									v-for="(item, index) in paidActiveMemberships"
									:key="index"
								>
									{{ item.title }}
								</li>
							</ul>
						</v-card-subtitle>
						<span
							v-if="user._membership_display_year"
							:style="{ color: user.hero_image.select_year_color_theme}"
							class="membershipCard__validityYear pt-3 pr-3"
						>
							{{ user._membership_display_year }}
						</span>
					</v-img>
					<template v-else>
						<v-card-title class="headline">
							{{ user.firstname }} {{ user.lastname }}
						</v-card-title>
						<v-card-subtitle v-if="user.active_memberships && user.active_memberships.length">
							<ul class="no-list">
								<li
									v-for="(item, index) in user.active_memberships"
									:key="index"
								>
									{{ item.title }}
								</li>
							</ul>
						</v-card-subtitle>
					</template>
					<v-card-text v-if="user.details && user.details.length">
						<v-alert
							v-if="user._userRestricted === true"
							type="error"
							prominent
						>
							<v-row align="center">
								<v-col class="grow">
									Sinulla ei ole maksettua jäsenyyttä!
								</v-col>
								<v-col class="shrink">
									<v-btn
										text
										class="ma-0"
										:to="{ name: 'invoiceContainer' }"
									>
										Siirry laskuihin
									</v-btn>
								</v-col>
							</v-row>
						</v-alert>
						<FilterableList
							:items="user.details"
							subtitle-src="label"
							title-src="value"
							:enable-click="(false)"
							:enable-search="(false)"
						/>
					</v-card-text>
					<v-divider />
					<v-card-actions>
						<v-btn
							:to="{ name: 'myAccount' }"
							color="secondary"
							text
						>
							<v-icon left>
								mdi-pencil
							</v-icon>
							Muokkaa tietojasi
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-container>

			<!-- Memberships -->
			<v-container class="container--narrow">
				<v-card>
					<v-card-title>
						Jäsenyydet
					</v-card-title>
					<v-card-text>
						<v-simple-table v-if="(user.active_memberships && user.active_memberships.length)">
							<thead>
								<tr>
									<th class="text-left">
										Nimi
									</th>
									<th class="text-right">
										Lasku
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(item, index) in user.active_memberships"
									:key="index"
								>
									<td>
										<router-link
											:to="{ name: 'club', params: { pagename: item.id } }"
											class="table__link"
										>
											{{ item.title }}
										</router-link>
									</td>
									<td class="text-right">
										<template v-if="item._invoice && item._invoice._status">
											<v-btn
												text
												:color="item._invoice._status.color ? item._invoice._status.color : null"
												:to="({ name: 'invoice', params: { pagename: item._invoice.id } })"
											>
												<v-icon
													v-if="item._invoice._status.icon"
													left
													small
												>
													{{ item._invoice._status.icon }}
												</v-icon>
												{{ item._invoice._status.text }}
											</v-btn>
										</template>
									</td>
								</tr>
							</tbody>
						</v-simple-table>
						<p v-else>
							Ei aktiivisia jäsenyyksiä.
						</p>
					</v-card-text>
				</v-card>
			</v-container>

			<!-- Pararatsastus -->
			<v-container
				v-if="user.c_vammaisratsastus && user.c_vammaisratsastus.length"
				class="container--narrow"
			>
				<v-card>
					<v-card-title>
						Pararatsastus
					</v-card-title>
					<v-card-text>
						<v-simple-table>
							<thead>
								<tr>
									<th class="text-left">
										Luokka
									</th>
									<th class="text-left">
										Tarkenne
									</th>
									<th class="text-right">
										Alkaen
									</th>
									<th class="text-right">
										Päättyen
									</th>
									<th class="text-left">
										Huomautukset
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(item, index) in user.c_vammaisratsastus"
									:key="index"
								>
									<td>
										<template v-if="item.c_vr_luokka">
											{{ item.c_vr_luokka.title }}
										</template>
									</td>
									<td>
										<template v-if="item.c_vr_tarkenne">
											{{ item.c_vr_tarkenne.title }}
										</template>
									</td>
									<td class="text-right">
										{{ item._startdate_formatted }}
									</td>
									<td class="text-right">
										{{ item._enddate_formatted }}
									</td>
									<td>
										{{ item.info_text }}
									</td>
								</tr>
							</tbody>
						</v-simple-table>
					</v-card-text>
				</v-card>
			</v-container>

			<!-- Entity degrees -->
			<v-container class="container--narrow">
				<v-card>
					<v-card-title>
						Toimihenkilöoikeudet
					</v-card-title>
					<v-card-text>
						<v-alert
							v-if="user.c_entity_degree_info"
							type="info"
						>
							{{ user.c_entity_degree_info }}
						</v-alert>
						<v-simple-table v-if="(user.entity_degree && user.entity_degree.length)">
							<thead>
								<tr>
									<th class="text-left">
										Nimi
									</th>
									<th class="text-right">
										Alkaen
									</th>
									<th class="text-right">
										Päättyen
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(item, index) in user.entity_degree"
									:key="index"
								>
									<td>{{ item.title }}</td>
									<td class="text-right">
										{{ item._startdate_formatted }}
									</td>
									<td class="text-right">
										{{ item._enddate_formatted }}
									</td>
								</tr>
							</tbody>
						</v-simple-table>
						<p v-else>
							Ei toimihenkilöoikeuksia.
						</p>
					</v-card-text>
				</v-card>
			</v-container>

			<!-- Licenses -->
			<v-container class="container--narrow">
				<v-card>
					<v-card-title>
						Kilpailuluvat ja vakuutukset
					</v-card-title>
					<v-card-text>
						<v-simple-table v-if="(user.active_licenses && user.active_licenses.length)">
							<thead>
								<tr>
									<th class="text-left">
										Nimi
									</th>
									<th class="text-left">
										Lisätiedot
									</th>
									<th class="text-left">
										Tila
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(item, index) in user.active_licenses"
									:key="index"
								>
									<td>{{ item.title }}</td>
									<td>{{ item.details }}</td>
									<td>{{ item.status }}</td>
								</tr>
							</tbody>
						</v-simple-table>
						<p v-else>
							Ei kilpailulupia.
						</p>
					</v-card-text>
					<v-divider />
					<v-card-text class="pa-3">
						<v-btn
							color="secondary"
							href="https://ratsastus.lisenssikauppa.fi/shop/"
							target="_blank"
							text
						>
							<v-icon left>
								mdi-currency-eur
							</v-icon>
							Osta laajennettu Sporttiturva
						</v-btn>
						<v-btn
							color="secondary"
							href="https://kipa2.ratsastus.fi/shop"
							target="_blank"
							text
						>
							<v-icon left>
								mdi-currency-eur
							</v-icon>
							Osta kilpailulupa
						</v-btn>
					</v-card-text>
				</v-card>
			</v-container>

			<!-- Merkit -->
			<v-container class="container--narrow">
				<v-card>
					<v-card-title>
						Merkit
					</v-card-title>
					<v-card-text>
						<v-simple-table v-if="(user.c_merkki && user.c_merkki.length)">
							<thead>
								<tr>
									<th class="text-left">
										Nimi
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(item, index) in user.c_merkki"
									:key="index"
								>
									<td>{{ item.title }}</td>
								</tr>
							</tbody>
						</v-simple-table>
						<p v-else>
							Ei merkkejä.
						</p>
					</v-card-text>
				</v-card>
			</v-container>

			<!-- Badges of merits -->
			<v-container class="container--narrow">
				<v-card>
					<v-card-title>
						Ansiomerkit
					</v-card-title>
					<v-card-text>
						<v-simple-table v-if="(user.badge_of_merits && user.badge_of_merits.length)">
							<thead>
								<tr>
									<th class="text-left">
										Nimi
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(item, index) in user.badge_of_merits"
									:key="index"
								>
									<td>{{ item.title }}</td>
								</tr>
							</tbody>
						</v-simple-table>
						<p v-else>
							Ei ansiomerkkejä.
						</p>
					</v-card-text>
				</v-card>
			</v-container>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'MembershipCard',
	computed: {
		...mapState([
			'user',
		]),
		paidActiveMemberships () {
			if (!this.user.active_memberships || !this.user.active_memberships.length) return []

			return this.user.active_memberships.filter(item => {
				return !item._invoice || item._invoice.state === 'paid'
			})
		},
	},
	mounted () {
		this.$api.Me.doRequest()
	},
}
</script>

<style lang="scss">
.membershipCard {
	&__validityYear {
		position: absolute;
		top: 0;
		right: 0;
		font-weight: bold;
		font-size: 1.2em;
	}
}
</style>
