<template>
	<div>
		<v-container
			v-if="item.hero_image"
			fluid
			pa-0
			class="container--narrow"
		>
			<v-img
				:src="item.hero_image.url"
				:alt="item.hero_image.alt"
				height="200"
			/>
		</v-container>
		<v-container class="container--narrow py-6">
			<template v-if="loading">
				Ladataan...
			</template>
			<template v-else-if="error">
				<p>{{ error }}</p>
			</template>
			<template v-else>
				<h1
					v-if="item.title"
					class="headline mb-5"
					v-html="item.title"
				/>
				<p
					v-if="item.summary"
					class="subtitle-1 mb-6"
				>
					{{ item.summary }}
				</p>
				<div
					v-if="item.content"
					v-html="item.content"
					class="copy"
				/>

				<!-- File groups -->
				<div
					v-if="item.file_groups && item.file_groups.length"
					class="mt-8"
				>
					<div
						v-for="group in item.file_groups"
						:key="group.id"
						class=""
					>
						<h2 class="text-h6">
							{{ group.title }}
						</h2>
						<FilterableList
							:items="group.files"
							:enable-click="(true)"
							:enable-search="(false)"
							redirect-url-src="url"
						/>
					</div>
				</div>
			</template>
		</v-container>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'Benefit',
	data: () => ({
		loading: true,
		error: '',
		item: {},
	}),
	computed: {
		...mapState([
			'benefits',
		]),
	},
	mounted () {
		try {
			// Find news item from the store
			this.item = this.benefits.find(item => {
				return item.name == this.$route.params.pagename
			})

			if (!this.item) throw 'Jäsenetua ei löytynyt!'

			this.loading = false
		} catch (error) {
			this.error = error
			this.loading = false
		}
	},
}
</script>
