<template>
	<div v-if="item">
		<v-container class="container--narrow">
			<v-card v-if="item._details && item._details.length">
				<v-card-title>
					Laskun tiedot
				</v-card-title>
				<v-card-text v-if="item._details && item._details.length">
					<FilterableList
						:items="item._details"
						:enable-click="false"
						:enable-search="false"
						subtitle-src="label"
						title-src="value"
					/>
				</v-card-text>

				<template v-if="item._is_payable || item._receipt_link">
					<v-divider />
					<v-card-actions>
						<v-btn
							v-if="item._is_payable === true"
							color="secondary"
							@click="createPaymentDialogOpen = true"
						>
							<v-icon left>
								mdi-currency-eur
							</v-icon>
							<span v-html="'Maksa ' + item._open_formatted + ' verkkomaksuna'" />
						</v-btn>
						<v-btn
							v-if="item._receipt_link"
							color="secondary"
							text
							:href="item._receipt_link"
							target="_blank"
						>
							<v-icon left>
								mdi-download
							</v-icon>
							Lataa kuitti
						</v-btn>
						<!-- Create payment dialog -->
						<v-dialog
							v-model="createPaymentDialogOpen"
							max-width="400"
							persistent
							scrollable
						>
							<v-card>
								<v-card-title>
									Maksa lasku
								</v-card-title>
								<v-divider />
								<v-card-text>
									<Spinner
										v-if="paymentLoading === true"
										:text="paymentLoadingText"
									/>
									<template v-else>
										Laskun maksaminen tapahtuu Svea Paymentin maksupalvelussa. Älä sulje selainta laskun maksamisen jälkeen, jotta maksutapahtuma kirjautuu oikein.
									</template>
									<!-- Payment form. Will be populated after successful payment creation -->
									<form
										v-if="paymentFormData && Object.keys(paymentFormData).length"
										ref="paymentForm"
										method="post"
										class="paymentForm"
										:action="paymentFormData.action"
										aria-hidden="true"
									>
										<v-alert
											type="info"
											dense
											class="mt-6"
										>
											Jos automaattinen uudelleenohjaus ei toimi, klikkaa alla olevaa nappia.
										</v-alert>
										<div class="text-center">
											<v-btn
												type="submit"
												color="primary"
											>
												<v-icon left>
													mdi-arrow-right
												</v-icon>
												Siirry maksamaan
											</v-btn>
										</div>
										<div
											v-if="paymentFormData.fields && Object.keys(paymentFormData.fields).length"
											class="paymentForm__fields"
										>
											<div
												v-for="(value, name, index) in paymentFormData.fields"
												:key="index"
												style="display: table-row;"
											>
												<label
													for="name"
													style="display: table-cell; width: 30%;"
												>
													{{ name }}
												</label>
												<input
													:id="name"
													:name="name"
													:value="value"
													style="display: table-cell; width: 100%;"
												>
											</div>
										</div>
									</form>
								</v-card-text>
								<v-divider />
								<v-card-actions>
									<v-btn
										text
										:disabled="paymentLoading"
										@click="createPaymentDialogOpen = false"
									>
										Peruuta
									</v-btn>
									<v-spacer />
									<v-btn
										color="primary"
										text
										:disabled="paymentLoading"
										@click="createPayment"
									>
										Siirry maksamaan
									</v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog>
						<!-- Check payment dialog -->
						<v-dialog
							v-model="checkPaymentDialogOpen"
							max-width="300"
							persistent
							scrollable
						>
							<v-card>
								<v-card-text>
									<Spinner
										text="Tarkistetaan maksutapahtumaa"
									/>
								</v-card-text>
							</v-card>
						</v-dialog>
					</v-card-actions>
				</template>
			</v-card>
		</v-container>
		<v-container
			v-if="item._is_paid === false"
			class="container--narrow"
		>
			<v-alert
				type="info"
				class="ma-0"
				border="left"
				colored-border
				elevation="2"
			>
				Voit maksaa laskun viitteellä
				<strong class="no-line-break">{{ item.reference_number }}</strong>
				Ratsastajainliiton tilille
				<strong class="no-line-break">FI33 4108 0010 3028 37</strong>.
				Viitenumero tulee laittaa viitekenttään, ei viestiksi.
			</v-alert>
		</v-container>
		<v-container class="container--narrow">
			<v-card>
				<v-card-title>
					Laskurivit
				</v-card-title>

				<v-data-table
					:headers="item._table_headers"
					:items="item._table_rows"
					:disable-sort="true"
					:disable-pagination="true"
					:hide-default-footer="true"
					:mobile-breakpoint="400"
					no-data-text="Ei laskurivejä"
				>
					<!-- eslint-disable -->
					<template #item.total="slotProps">
					<!-- eslint-enable -->
						<span v-html="slotProps.item.total" />
					</template>
				</v-data-table>
			</v-card>
		</v-container>
		<PaymentGuideDialog v-if="item._is_payable === true" />
	</div>
</template>

<script>

import { mapState } from 'vuex'
import PaymentGuideDialog from '@/components/PaymentGuideDialog.vue'

export default {
	name: 'Invoice',
	data: () => ({
		item: null,
		createPaymentDialogOpen: false,
		paymentFormData: {},
		paymentLoading: false,
		paymentLoadingText: null,
		checkPaymentDialogOpen: false,
	}),
	components: {
		PaymentGuideDialog,
	},
	computed: {
		...mapState({
			items: state => state.user.invoice,
		}),
	},
	mounted () {
		this.loadItem()

		// Check payment status
		if (Object.keys(this.$route.query).length) {
			this.$nextTick(() => {
				this.$store.commit('setBackButtonHistoryMode', false)
			})

			if (this.$route.query.status === 'ok') {
				this.checkPayment(this.$route.query)
			} else if (this.$route.query.status === 'cancel') {
				this.$store.dispatch('setNotifyMessage', 'Maksu peruutettiin')
			} else if (this.$route.query.status === 'error') {
				this.$store.dispatch('setNotifyError', 'Maksussa tapahtui virhe')
			}

			// Clear query string
			this.$router.replace({'query': null})
		}
	},
	methods: {
		// Find current page from the store
		loadItem () {
			this.item = this.items.find(item => {
				return item.id == this.$route.params.pagename
			})

			if (!this.item) {
				this.$router.replace({ name: 'error404' })
			}
		},

		// Create a new payment and redirect user to Svea Payment
		createPayment () {
			this.paymentLoading = true
			this.paymentLoadingText = 'Luodaan maksutapahtumaa'

			// Define base URL for the current page
			const baseUrl = window.location.href.split(/\?|#/)[0]

			// Request payment data
			this.$api.Invoice.doRequest({
				method: 'POST',
				url: 'createpayment/',
				body: {
					invoice_id: this.item.id,
					pmt_okreturn: baseUrl + '?status=ok',
					pmt_errorreturn: baseUrl + '?status=error',
					pmt_cancelreturn: baseUrl + '?status=cancel',
					pmt_delayedpayreturn: baseUrl + '?status=cancel',
				},
			}).on('done', res => {
				if (!res.body.error) {
					// Populate payment form
					this.paymentFormData = Object.assign({}, res.body.paymentFormData)

					// Make sure that form is fully rendered and then post it
					this.$nextTick(() => {
						this.paymentLoadingText = 'Siirrytään maksupalveluun'

						if (this.$refs.paymentForm) {
							this.$refs.paymentForm.submit()
						}
					})
				} else {
					// Reload invoice from the store to update the view
					this.loadItem()

					this.paymentLoading = false
				}
			}).on('fail', () => {
				// Reload invoice from the store to update the view
				this.loadItem()

				this.paymentLoading = false
			})
		},

		// Check payment status
		checkPayment (data = {}) {
			this.checkPaymentDialogOpen = true

			this.$api.Invoice.doRequest({
				method: 'POST',
				url: 'checkpayment/',
				body: data,
			}).on('done', () => {
				// Reload invoice from the store to update the view
				this.loadItem()
			}).on('finish', () => {
				this.checkPaymentDialogOpen = false
			})
		},
	},
}
</script>

<style lang="scss" scoped>

.paymentForm {
	&__fields {
		overflow: hidden;
		height: 0;
	}

}

</style>
