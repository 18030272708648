<template>
	<div>
		<template v-if="loading">
			Ladataan...
		</template>
		<template v-else-if="error">
			<p>{{ error }}</p>
		</template>
		<template v-else-if="item">
			<!-- Details -->
			<v-container class="container--narrow">
				<v-card>
					<v-row
						no-gutters
					>
						<v-col
							v-if="item._profile_pic_thumb"
							class="pa-3 order-sm-last"
							style="max-width: 100px;"
						>
							<v-img
								:src="item._profile_pic_thumb"
								contain
							/>
						</v-col>
						<v-col
							cols="12"
							sm="auto"
							class="flex-sm-grow-1 flex-sm-shrink-0"
						>
							<v-card-title v-if="item.title">
								{{ item.title }}
							</v-card-title>
							<v-card-text>
								<!-- Details -->
								<FilterableList
									v-if="item._details"
									:items="item._details"
									:enable-click="(false)"
									:enable-search="(false)"
								>
									<!-- Inject invoice details to the list -->
									<template #title="slotProps">
										<template v-if="slotProps.item.name === 'invoice_status'">
											<v-btn
												text
												:color="item._invoice._status.color ? item._invoice._status.color : null"
												:to="({ name: 'invoice', params: { pagename: item._invoice.id } })"
											>
												<v-icon
													v-if="item._invoice._status.icon"
													left
													small
												>
													{{ item._invoice._status.icon }}
												</v-icon>
												{{ item._invoice._status.text }}
											</v-btn>
										</template>
										<template v-else>
											{{ slotProps.item.title }}
										</template>
									</template>
								</FilterableList>
							</v-card-text>
						</v-col>
					</v-row>
				</v-card>
			</v-container>

			<!-- Description -->
			<v-container
				v-if="item._description"
				class="container--narrow"
			>
				<v-card>
					<v-card-title>
						<v-icon
							left
							color="secondary"
						>
							mdi-information
						</v-icon>
						Seura tiedottaa
					</v-card-title>
					<v-card-text>
						<span v-html="item._description" />
					</v-card-text>
				</v-card>
			</v-container>

			<!-- Contacts -->
			<v-container
				v-if="item._contacts && item._contacts.length"
				class="container--narrow"
			>
				<v-card>
					<v-card-title>
						<v-icon
							left
							color="secondary"
						>
							<template v-if="item._contacts.length == 1">
								mdi-account
							</template>
							<template v-else>
								mdi-account-multiple
							</template>
						</v-icon>

						<template v-if="item._contacts.length == 1">
							Jäsensihteeri
						</template>
						<template v-else>
							Jäsensihteerit
						</template>
					</v-card-title>
					<v-card-text>
						<ul class="no-list">
							<li
								v-for="(contact, index) in item._contacts"
								:key="contact.id"
								:class="index != item._contacts.length - 1 ? 'mb-6' : null"
							>
								<h3>
									{{ contact.title }}
								</h3>
								<dl class="detailList mt-1">
									<div
										v-if="contact.mobile"
										class="detailList__row"
									>
										<dt class="detailList__label">
											Puhelin
										</dt>
										<dd class="detailList__value">
											{{ contact.mobile }}
										</dd>
									</div>
									<div
										v-if="contact.email_address"
										class="detailList__row"
									>
										<dt class="detailList__label">
											Sähköposti
										</dt>
										<dd class="detailList__value">
											<a :href="('mailto:' + contact.email_address)">
												{{ contact.email_address }}
											</a>
										</dd>
									</div>
								</dl>
							</li>
						</ul>
					</v-card-text>
				</v-card>
			</v-container>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'Club',
	data: () => ({
		loading: true,
		error: '',
		item: {},
	}),
	computed: {
		...mapState({
			items: state => state.user.active_memberships,
		}),
	},
	mounted () {
		try {
			// Find news item from the store
			this.item = this.items.find(item => {
				return item.id == this.$route.params.pagename
			})

			if (!this.item) throw 'Jäsenyyttä ei löytynyt!'

			this.loading = false
		} catch (error) {
			this.error = error
			this.loading = false
		}
	},
}
</script>
