<template>
	<div>
		<!-- Output child page -->
		<router-view />

		<!-- Output navigation if we're not on child page -->
		<template v-if="$route.name == 'invoiceContainer'">
			<FilterableList
				v-if="items.length"
				:enable-search="(false)"
				:items="items"
				icon-src="_icon"
				icon-color-src="_icon_color"
				title-src="_list_title"
				summary-src="_list_summary"
				meta-src="_status_text"
				@itemClick="itemClick"
			/>
			<v-alert
				v-else
				type="info"
				class="ma-3"
			>
				Ei laskuja
			</v-alert>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'InvoiceContainer',
	data: () => ({
		tab: null,
	}),
	computed: {
		...mapState({
			items: state => state.user.invoice,
		}),
	},
	methods: {
		itemClick (item) {
			this.$router.push({ name: 'invoice', params: { pagename: item.id } })
		},
	},
	mounted () {
		this.$api.Me.doRequest()
	},
}
</script>
