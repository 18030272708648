<template>
	<div>
		<!-- Output child page -->
		<router-view />

		<!-- Output navigation if we're not on child page -->
		<template v-if="$route.name == 'newsContainer'">
			<v-container class="container--narrow">
				<FilterableList
					v-if="items.length"
					:items="items"
					@itemClick="itemClick"
				/>
				<v-alert
					v-else
					type="info"
					class="ma-3"
				>
					Ei uutisia
				</v-alert>
			</v-container>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'NewsContainer',
	computed: {
		...mapState({
			items: state => state.news,
		}),
	},
	methods: {
		itemClick (item) {
			this.$router.push({ name: 'news', params: { pagename: item.name } })
		},
	},
	mounted () {
		this.$api.News.doRequest()
	},
}
</script>
