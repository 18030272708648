<template>
	<v-dialog
		v-model="showInvoiceGuideDialog"
		max-width="400"
	>
		<v-card>
			<v-card-title class="headline">
				<v-icon class="alert-icon">
					mdi-alert-circle
				</v-icon>
				Uusi jäsen?
			</v-card-title>
			<v-card-text>
				Mikäli olet juuri liittynyt jäseneksi ja suoritit jäsenmaksusi liittymisesi yhteydessä, muuttuu laskusi tila parin päivän viiveellä maksetuksi. Ethän näin ollen suorita laskuasi toistamiseen, kiitos.
			</v-card-text>
			<v-card-actions>
				<v-btn
					color="secondary"
					@click="showInvoiceGuideDialog = false"
				>
					Ok
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'PaymentGuideDialog',
	computed: {
		...mapState([
			'showInvoiceGuideDialog',
		]),
		showInvoiceGuideDialog: {
			get () {
				return this.$store.state.showInvoiceGuideDialog
			},
			set (val) {
				this.$store.commit('setShowInvoiceGuideDialog', val)
			},
		},
	},
}
</script>

<style lang="scss" scoped>
.alert-icon {
	margin-right: 0.5em;
}

</style>
