<template>
	<div v-if="item">
		<v-container
			v-if="item.hero_image"
			fluid
			pa-0
			class="container--narrow"
		>
			<v-img
				:src="item.hero_image.url"
				:alt="item.hero_image.alt"
				height="200"
			/>
		</v-container>
		<v-container class="container--narrow py-6">
			<h1
				v-if="item.title"
				class="headline mb-5"
				v-html="item.title"
			/>
			<p
				v-if="item.summary"
				class="subtitle-1 mb-6"
			>
				{{ item.summary }}
			</p>
			<div
				v-if="item.content"
				v-html="item.content"
				class="copy"
			/>

			<!-- File groups -->
			<div
				v-if="item.file_groups && item.file_groups.length"
				class="mt-8"
			>
				<div
					v-for="group in item.file_groups"
					:key="group.id"
					class=""
				>
					<h2 class="text-h6">
						{{ group.title }}
					</h2>
					<FilterableList
						:items="group.files"
						:enable-click="(true)"
						:enable-search="(false)"
						redirect-url-src="url"
					/>
				</div>
			</div>
		</v-container>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'Page',
	data: () => ({
		error: '',
		item: null,
	}),
	computed: {
		...mapState([
			'pages',
		]),
	},
	mounted () {
		// Find current category from the store
		const category = this.pages.find(item => {
			return item.name == this.$route.params.categoryname
		})

		if (!category) {
			this.$router.replace({ name: 'Error404' })
		}

		// Find current page from the category children
		this.item = category.children.find(item => {
			return item.name == this.$route.params.pagename
		})

		if (!this.item) {
			this.$router.replace({ name: 'Error404' })
		}
	},
}
</script>
