<template>
	<div>
		<!-- Output child page -->
		<router-view />

		<!-- Output navigation if we're not on child page -->
		<template v-if="$route.name == 'clubContainer'">
			<v-container class="container--narrow">
				<v-row
					v-if="items.length"
					no-gutters
				>
					<v-col
						v-for="item in items"
						:key="item.id"
						cols="12"
						sm="6"
						class="pa-3"
					>
						<v-card
							height="100%"
							@click="itemClick(item)"
						>
							<v-row
								no-gutters
							>
								<v-col
									:cols="(item._profile_pic_thumb) ? 8 : 12"
									class="py-3"
								>
									<v-card-title
										v-if="item.title"
										class="subtitle-1 font-weight-bold pt-0"
									>
										<span v-html="item.title" />
									</v-card-title>
									<v-card-text
										v-if="item._description_preview"
									>
										<span v-html="item._description_preview" />
									</v-card-text>
								</v-col>
								<v-col
									v-if="item._profile_pic_thumb"
									cols="4"
									class="pa-3"
								>
									<v-img
										:src="item._profile_pic_thumb"
										contain
									/>
								</v-col>
							</v-row>

							<!-- Invoice details -->
							<template v-if="item._invoice && item._invoice._status">
								<v-divider />
								<v-card-actions>
									<v-btn
										text
										:color="item._invoice._status.color ? item._invoice._status.color : null"
										:to="({ name: 'invoice', params: { pagename: item._invoice.id } })"
									>
										<v-icon
											v-if="item._invoice._status.icon"
											left
											small
										>
											{{ item._invoice._status.icon }}
										</v-icon>
										{{ item._invoice._status.text }}
									</v-btn>
								</v-card-actions>
							</template>
						</v-card>
					</v-col>
				</v-row>
				<v-alert
					v-else
					type="info"
				>
					Ei aktiivisia jäsenyyksiä
				</v-alert>
			</v-container>

			<!-- Club news -->
			<v-container
				v-if="articles && articles.length"
				class="container--narrow"
			>
				<h2 class="text-h5 ml-3">
					Ajankohtaista seuratoiminnassa
				</h2>
				<FilterableList
					:items="articles"
					:enable-search="false"
				/>
			</v-container>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'ClubContainer',
	computed: {
		...mapState({
			items: state => state.user.active_memberships,
			articles: state => state.clubs.articles,
		}),
	},
	methods: {
		itemClick (item) {
			this.$router.push({ name: 'club', params: { pagename: item.id } })
		},
	},
	mounted () {
		this.$api.Me.doRequest()
		this.$api.Clubs.doRequest()
	},
}
</script>
