<template>
	<div>
		<template v-if="user._userRestricted === true">
			<v-alert
				type="info"
				class="ma-3"
			>
				Maksamalla jäsenmaksun pääset käsiksi kattaviin jäsenetuihin sekä materiaaleihin.
			</v-alert>
		</template>
		<template v-else>
			<!-- Output child page -->
			<router-view />

			<!-- Output navigation if we're not on child page -->
			<template v-if="$route.name == 'benefitContainer'">
				<v-container
					v-if="items.length"
					class="py-6"
				>
					<v-row
						no-gutters
					>
						<v-col
							v-for="item in items"
							:key="item.id"
							cols="12"
							sm="6"
							md="4"
							xl="3"
							class="pa-3"
						>
							<v-card
								:to="item.attachment ? null : { name: 'benefit', params: { pagename: item.name } }"
								:href="item.attachment ? item.attachment : null"
								:target="item.attachment ? '_blank' : null"
								:class="[
									item.highlight === 1 ? 'yellow lighten-4' : null,
								]"
								height="100%"
							>
								<v-row
									no-gutters
								>
									<v-col
										:cols="(item.logo) ? 8 : 12"
										class="py-3"
									>
										<v-card-title
											v-if="item.title"
											class="subtitle-1 font-weight-bold pt-0"
										>
											<span v-html="item.title" />
										</v-card-title>

										<v-card-text
											v-if="item.summary"
										>
											<span v-html="item.summary" />
										</v-card-text>
									</v-col>
									<v-col
										v-if="item.logo"
										cols="4"
										class="pa-3"
									>
										<v-img
											:src="item.logo.url"
											contain
										/>
									</v-col>
								</v-row>
							</v-card>
						</v-col>
					</v-row>
				</v-container>
				<v-alert
					v-else
					type="info"
					class="ma-3"
				>
					Ei jäsenetuja
				</v-alert>
			</template>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'BenefitContainer',
	computed: {
		...mapState({
			user: state => state.user,
			items: state => state.benefits,
		}),
	},
	methods: {
		itemClick (item) {
			this.$router.push({ name: 'benefit', params: { pagename: item.name } })
		},
	},
	mounted () {
		this.$api.Benefits.doRequest()
	},
}
</script>
