<template>
	<v-container class="container--narrow">
		<v-card>
			<v-card-text>
				<SchemaToForm
					v-model="formData"
					:schema="config.forms.myAccount"
					:disabled="saveLoading"
					:debug="false"
					@valid="formValid = true"
					@invalid="formValid = false"
					@change="$store.commit('setConfirmNavigation', true)"
				/>
			</v-card-text>
			<v-divider />
			<v-card-actions>
				<v-spacer />
				<v-btn
					color="success"
					:loading="saveLoading"
					:disabled="saveLoading || !formValid"
					@click="save"
					text
				>
					Tallenna
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-container>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'MyAccount',
	data: () => ({
		formData: {},
		saveLoading: false,
		formValid: false,
	}),
	computed: {
		...mapState([
			'user',
			'config',
		]),
	},
	methods: {
		save () {
			this.saveLoading = true

			this.$api.Me.doRequest({
				method: 'POST',
				body: this.formData,
			}).on('done', res => {
				// Since the register may reformat values (i.e. telephone number),
				// replace form data with server response.
				if (res.body.item) {
					this.formData = res.body.item
				}

				// Clear navigation confirm
				if (!res.body.error){
					this.$nextTick(() => {
						this.$store.dispatch('clearConfirmNavigation')
					})
				}
			}).on('finish', () => {
				this.saveLoading = false
			})
		},
	},
	mounted () {
		this.formData = JSON.parse(JSON.stringify(this.user))
	},
}
</script>
