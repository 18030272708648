import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		error: {},
		config: {
			social_media_links: [],
		},
		settings: {
			darkMode: false,
		},
		user: {},
		clubs: {},
		news: [],
		events: [],
		benefits: [],
		pages: [],
		notifyMessage: '',
		notifyMessageVisible: false,
		notifyError: '',
		notifyErrorVisible: false,
		updateAvailable: false,
		confirmNavigation: false,
		confirmNavigationRoute: {},
		confirmNavigationDialog: false,
		backButtonHistoryMode: false,
		showInvoiceGuideDialog: localStorage.getItem('showInvoiceGuideDialog') !== null ? localStorage.getItem('showInvoiceGuideDialog') === 'true' : true,
	},
	mutations: {
		setError (state, payload) {
			state.error = Object.assign({
				'title': 'Virhe',
				'summary': 'Sovelluksen lataamisessa tapahtui virhe.',
				'buttonTitle': 'Kirjaudu uudelleen',
				'buttonHref': '/app/',
			}, payload)
		},

		clearError (state) {
			state.error = {}
		},

		setConfig (state, payload) {
			state.config = Object.assign({}, payload)
		},

		setSettings (state, payload) {
			state.settings = Object.assign({}, payload)
		},

		setUser (state, payload) {
			state.user = payload
		},

		setClubs (state, payload) {
			state.clubs = payload
		},

		setNews (state, payload) {
			state.news = payload
		},

		setEvents (state, payload) {
			state.events = payload
		},

		setBenefits (state, payload) {
			state.benefits = payload
		},

		setPages (state, payload) {
			state.pages = payload
		},

		setNotifyMessage (state, payload) {
			state.notifyMessage = payload
		},

		setNotifyMessageVisible (state, payload) {
			state.notifyMessageVisible = payload
		},

		setNotifyError (state, payload) {
			state.notifyError = payload
		},

		setNotifyErrorVisible (state, payload) {
			state.notifyErrorVisible = payload
		},

		setUpdateAvailable (state, payload) {
			state.updateAvailable = payload
		},

		setConfirmNavigation (state, payload) {
			state.confirmNavigation = payload
		},

		setConfirmNavigationRoute (state, payload) {
			state.confirmNavigationRoute = payload
		},

		setConfirmNavigationDialog (state, payload) {
			state.confirmNavigationDialog = payload
		},

		setBackButtonHistoryMode (state, payload) {
			state.backButtonHistoryMode = payload
		},
		setShowInvoiceGuideDialog (state, payload) {
			state.showInvoiceGuideDialog = payload
			localStorage.setItem('showInvoiceGuideDialog', payload)
		},
	},
	actions: {
		clearData ({ commit }) {
			return new Promise(resolve => {
				commit('clearError')
				commit('setConfig', {})
				commit('setUser', {})
				commit('setClubs', {})
				commit('setNews', [])
				commit('setEvents', [])
				commit('setBenefits', [])
				commit('setPages', [])
				commit('setConfirmNavigation', false)
				commit('setConfirmNavigationDialog', false)
				commit('setConfirmNavigationRoute', {})
				commit('setBackButtonHistoryMode', false)
				commit('setShowInvoiceGuideDialog', true)

				resolve()
			})
		},

		getSettings ({ commit }) {
			return new Promise(resolve => {
				let settings = localStorage.getItem('settings') || '{}'

				commit('setSettings', JSON.parse(settings))

				resolve()
			})
		},

		setSettings ({ commit }, payload) {
			commit('setSettings', payload)

			// Save to localstorage
			localStorage.setItem('settings', JSON.stringify(payload))
		},

		clearConfirmNavigation ({ commit }) {
			commit('setConfirmNavigation', false)
			commit('setConfirmNavigationDialog', false)
			commit('setConfirmNavigationRoute', {})
		},

		setNotifyMessage ({ commit }, payload) {
			commit('setNotifyMessageVisible', true)
			commit('setNotifyMessage', payload)
		},

		setShowInvoiceGuideDialog ({ commit }, payload) {
			commit('setShowInvoiceGuideDialog', false)
			commit('setShowInvoiceGuideDialog', payload)
		},

		setNotifyError ({ commit }, payload) {
			commit('setNotifyErrorVisible', true)
			commit('setNotifyError', payload)
		},
	},
})
